import { Language, LanguageType, validLanguages } from '@nx-smartmonkey/shared/domain';
import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useQuery } from '@tanstack/react-query';
import { IntlShape } from 'react-intl';
import { translationsService } from '../../application/TranslationsService';
import { TranslationsActionType } from '../../context/translations/actions';
import { useTranslationsContext } from '../../context/translations/context';

const languageShort = (language: any) => language.split(/[-_]/)[0];

type UseRetrieveTranslationsResponse = ServiceResponse<() => void, Record<string, IntlShape>> & {
  activeLanguage?: IntlShape;
};

// Hooks act as 'Adapter' layer.
export const useRetrieveTranslations = (): UseRetrieveTranslationsResponse => {
  const { translationsState, translationsDispatch } = useTranslationsContext();

  let language: Language;

  switch (languageShort(navigator.language)) {
    case `eu`:
    case `es`:
    case `gl`:
      language = Language.create(`es`);
      break;
    case `ca`:
      language = Language.create(`ca`);
      break;
    case `pt`:
      language = Language.create(`pt`);
      break;
    case `de`:
      language = Language.create(`de`);
      break;
    case `fr`:
      language = Language.create(`fr`);
      break;
    default:
      language = Language.create(`en`);
  }

  const {
    isInitialLoading: isLoading,
    refetch,
    isError,
    isSuccess,
  } = useQuery(
    [`customers_translations`],
    (): Promise<{ language: LanguageType; translations: Record<string, string> }[]> =>
      translationsService.getTranslations({ languages: validLanguages }),
    {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (translations) => {
        translationsDispatch({ type: TranslationsActionType.SET_TRANSLATIONS, payload: { translations } });
        translationsDispatch({ type: TranslationsActionType.SET_ACTIVE_LANGUAGE, payload: { language } });
      },
      onError: (err: any) => {
        console.info(`Customers Retrieve Translations Error: `, err?.response);
      },
    }
  );

  return {
    error: isError,
    isLoading,
    success: isSuccess,
    run: refetch,
    activeLanguage: translationsState.activeLanguage,
    data: translationsState.translations,
  };
};
