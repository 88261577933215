import { getStopStatusColor } from '../constants/Colors';
import { StopStatus } from '../domain/stop/StopStatus';

type StopStatusBarTypes = {
  status: StopStatus;
};

export const StopStatusBar = ({ status }: StopStatusBarTypes) => {
  return (
    <div
      style={{
        width: `4px`,
        height: `100%`,
        backgroundColor: getStopStatusColor(status),
        borderRadius: `4px`,
      }}
    />
  );
};
