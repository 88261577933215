import { Chip } from '@mui/material';

import React from 'react';
import { getStopStatusColor } from '../constants/Colors';
import { StopStatus } from '../domain/stop/StopStatus';
import { Text } from '../react-components/Text';

interface StatusChipProps {
  chipStyles: React.CSSProperties;
  label: React.ReactElement;
}

const StatusChip = ({ chipStyles, label }: StatusChipProps) => {
  return (
    <Chip
      style={{
        height: `26px`,
        width: `120px`,
        borderRadius: `3px`,
        ...chipStyles,
      }}
      label={label}
    />
  );
};

export const StopStatusChip = (props: {
  status: StopStatus;
  chipStyles?: React.CSSProperties;
  labelStyles?: React.CSSProperties;
}) => {
  const { status, chipStyles, labelStyles } = props;
  const backgroundColor = getStopStatusColor(status, true);
  const textColor = getStopStatusColor(status);

  return (
    <StatusChip
      label={<Text variant="body" style={{ ...labelStyles, color: textColor }} intlId={`stop.status.${status}`} />}
      chipStyles={{
        ...chipStyles,
        backgroundColor,
      }}
    />
  );
};
