import tinycolor from 'tinycolor2';

// const OLD_colors = [
//   '#2196F3',
//   '#CDDC39',
//   '#d2190b',
//   '#607D8B',
//   '#af94e8',
//   '#795548',
//   '#FFEB3B',
//   '#ace4fe',
//   '#2830C9',
//   '#009688',
//   '#F44336',
//   '#673AB7',
//   '#D39C11',
//   '#9927C9',
//   '#2E8938',
//   '#FFC107',
//   '#02BCD4',
//   '#511414',
//   '#F906CB',
//   '#5B96FF',
//   '#2b387c',
//   '#8BC34A',
//   '#ffb8a2',
//   '#d1d077',
//   '#f4df96',
// ];

// OLD PLANNER COLORS = [
//   `#00A7FF`,
//   // '#A1E000',
//   `#D2910F`,
//   `#C02178`,
//   `#00A5E0`,
//   `#776E69`,
//   `#B5405B`,
//   `#558B87`,
//   `#94A33E`,
//   `#7B30B0`,
//   `#9B5746`,
//   `#3E54A3`,
//   `#8D3F81`,
//   `#4070A0`,
//   `#00E0D5`,
//   `#BB5C3A`,
//   `#5F54A0`,
//   `#636C92`,
//   `#7B6569`,
// ];

type IColorOptions = {
  modifiers?: number[];
  colors?: string[];
};

const defaultColorModifiers = [0, 25, 15, 7, -7, -15, -25];

export const RoutalDefaultColors = [
  `#2FBBF7`,
  `#F3801C`,
  `#FA6786`,
  `#AC58FF`,
  `#53ED91`,
  `#FFC2CD`,
  `#FFD058`,
  `#ED53E7`,
  `#5375ED`,
  `#ABE1FC`,
  `#DCB9FF`,
  `#F0A596`,
  `#F8E99D`,
  `#AFEFD2`,
  `#EE99E1`,
  `#99A2EE`,
];

const defaultOptions = {
  colors: RoutalDefaultColors,
  modifiers: defaultColorModifiers,
};

export const getColorByIndex = (index: number, options?: IColorOptions) => {
  const { colors, modifiers } = { ...defaultOptions, ...options };

  const colorModifiers = modifiers.length === 0 || modifiers[0] !== 0 ? [0, ...modifiers] : modifiers;

  const moduleIndex = index % (colorModifiers.length * colors.length);

  const colorIndex = moduleIndex % colors.length;
  const modifierIndex = Math.abs(moduleIndex / colors.length);

  const tColor = tinycolor(colors[colorIndex]);

  const cAmount = colorModifiers[modifierIndex];

  const newColor =
    cAmount > 0 ? tColor.darken(Math.abs(cAmount)).toString() : tColor.lighten(Math.abs(cAmount)).toString();

  return newColor;
};

enum WarningColors {
  light = `#FED7AE`,
  medium = `#F0A51B`,
  dark = `#BF851A`,
}

enum IncompleteColors {
  light = `#FED7AE`,
  medium = `#F0A51B`,
}

enum ErrorColors {
  light = `#F8A19B`,
  medium = `#D62C20`,
  dark = `#8A1E17`,
}

enum SuccessColors {
  light = `#C4E8CF`,
  medium = `#00BF8F`,
  dark = `#007558`,
}

const BasicColors = {
  primary40n: `#EAF2EE`, // primary -40
  primary20n: `#BCD3CB`, // primary -20
  primary00: `#90B1AA`, // primary 00
  primary20: `#678E89`, // primary 20
  primary40: `#406867`, // primary 40
  primary60: `#003639`, // primary 60
  primary80: `#072120`, // primary 80
  secondary40n: `#FDFDFE`, // secondary -40
  secondary20n: `#CBD2D7`, // secondary -20
  secondary00: `#9AA5AF`, // secondary 00
  secondary20: `#6B7785`, // secondary 20
  secondary40: `#3E4959`, // secondary 40
  secondary60: `#2E3744`, // secondary 60
  secondary80: `#1F252E`, // secondary 80
  neutral02n: `#FFFFFF`, // neutral -02
  neutral00: `#FAFAFA`, // neutral 00
  neutral02: `#F5F5F5`, // neutral 02
  neutral05: `#EEEEEE`, // neutral 05
  neutral10: `#D9D9D9`, // neutral 10
  neutral20: `#BEBEBE`, // neutral 20
  neutral40: `#4D4D4D`, // neutral 40
  neutral60: `#262626`, // neutral 60,
  success: SuccessColors,
  warning: WarningColors,
  error: ErrorColors,
};

export const RoutalPalette = {
  ...BasicColors,
  button: {
    primary: {
      defaultColor: BasicColors.primary40,
      hoverColor: BasicColors.primary20,
    },
    secondary: {
      defaultColor: BasicColors.secondary40,
      hoverColor: BasicColors.secondary20,
    },
    danger: {
      defaultColor: BasicColors.error.medium,
      hoverColor: BasicColors.error.light,
    },
  },

  text: BasicColors.primary80,

  stopStatusColors: {
    pending: {
      light: BasicColors.neutral10,
      medium: BasicColors.neutral40,
    },
    incomplete: IncompleteColors,
    completed: SuccessColors,
    canceled: ErrorColors,
  },

  routeStatusColors: {
    pending: {
      light: BasicColors.neutral10,
      medium: BasicColors.neutral40,
    },
    in_transit: IncompleteColors,
    finished: SuccessColors,
  },

  planStatusColors: {
    draft: {
      light: `#DFE6ED`,
      medium: `#5D6E7D`,
    },
    planning: {
      light: `#CFE3FF`,
      medium: `#536074`,
    },
    in_progress: {
      light: `#D8FFF5`,
      medium: `#229075`,
    },
    finished: {
      light: `#C4E1DB`,
      medium: `#5E7D77`,
    },
  },

  white: BasicColors.neutral00,

  // TODO: HAY QUE IR SUSTITUYENDO LOS GREYS DEL CÓDIGO POR LOS NEUTRALS QUE TENGAN EL MISMO CÓDIGO.
  grey1: `#717679`,
  grey2: `#F2F5F7`,
  grey3: `#CACACA`,
  grey4: `#E0E0E0`,
  grey5: BasicColors.neutral10,
  grey6: BasicColors.neutral02,
  grey7: BasicColors.neutral20,
  grey8: `#D2D2D2`,
  grey9: BasicColors.neutral05,
  grey10: `#FBFBFB`,
  grey11: `#777777`,
  grey12: `#DBDBDB`,
  grey13: `#707070`,
  routeBaseColor: BasicColors.primary40,
};
